import React from 'react'

export default function LogoSection() {
  const logos = [
    'logos3.png',
    'logos2.png',
    'logos4.png',
    'logos1.png',
  ]

  return (
    <section className="py-8 md:py-12 bg-gray-50">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-1">
          {logos.map((logo, index) => (
            <div 
              key={index} 
              className="flex items-center justify-center px-2"
            >
              <div className="relative w-full max-h-[50px] aspect-[3/2] grayscale brightness-[1.5] contrast-100  transition-all duration-300">
                <img
                  src={logo}
                  alt={`Cliente ${index + 1}`}
                  className="absolute inset-0 w-full h-full object-contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
