import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Menu, X } from 'lucide-react'

export default function MainNav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  
  const isHomePage = location.pathname === '/';

  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    
    // Si estamos en la página principal, solo scroll a la sección
    if (isHomePage) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(false);
      }
    } else {
      // Si estamos en otra página, navega a la página principal y luego al anchor
      navigate('/', { state: { scrollToId: sectionId } });
    }
  };

  useEffect(() => {
    if (location.state && location.state.scrollToId) {
      const element = document.getElementById(location.state.scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <header className="sticky top-0 w-full bg-white z-50 border-b border-gray-200 shadow-sm">
      <div className="relative container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/">
              <img src="logo.png" alt="Logo" className="h-14 w-auto" />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex lg:items-center lg:gap-8">
          <a 
              href="#atencion" 
              onClick={(e) => handleNavClick(e, 'atencion')}
              className="text-sm font-medium hover:text-[#b7221c] transition-colors"
            >
              ATENCIÓN EMPRESAS
            </a>
            <a 
              href="#areas-practica" 
              onClick={(e) => handleNavClick(e, 'areas-practica')}
              className="text-sm font-medium hover:text-[#b7221c] transition-colors"
            >
              ÁREAS DE PRÁCTICA
            </a>
            <a 
              href="#capacitacion" 
              onClick={(e) => handleNavClick(e, 'capacitacion')}
              className="text-sm font-medium hover:text-[#b7221c] transition-colors"
            >
              CAPACITACIÓN
            </a>
            <a 
              href="#associates" 
              onClick={(e) => handleNavClick(e, 'associates')}
              className="text-sm font-medium hover:text-[#b7221c] transition-colors"
            >
              INTEGRANTES
            </a>
            <a 
              href="#publications" 
              onClick={(e) => handleNavClick(e, 'publications')}
              className="text-sm font-medium hover:text-[#b7221c] transition-colors"
            >
              PUBLICACIONES
            </a>
          </nav>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="lg:hidden p-2 rounded-md hover:bg-gray-100 transition-colors"
          >
            {isMenuOpen ? <X /> : <Menu />}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="absolute top-16 left-0 right-0 bg-white border-b shadow-lg lg:hidden z-50">
            <nav className="container mx-auto px-4 py-4 flex flex-col gap-4">
              <a 
                href="#atencion"
                onClick={(e) => handleNavClick(e, 'atencion')}
                className="text-sm font-medium hover:text-[#b7221c] py-2 transition-colors"
              >
                ATENCIÓN EMPRESAS
              </a>
              <a 
                href="#areas-practica"
                onClick={(e) => handleNavClick(e, 'areas-practica')}
                className="text-sm font-medium hover:text-[#b7221c] py-2 transition-colors"
              >
                ÁREAS DE PRÁCTICA
              </a>
              <a 
                href="#capacitacion"
                onClick={(e) => handleNavClick(e, 'capacitacion')}
                className="text-sm font-medium hover:text-[#b7221c] py-2 transition-colors"
              >
                CAPACITACIÓN
              </a>
              <a 
                href="#associates"
                onClick={(e) => handleNavClick(e, 'associates')}
                className="text-sm font-medium hover:text-[#b7221c] py-2 transition-colors"
              >
                INTEGRANTES
              </a>
              <a 
                href="#publications"
                onClick={(e) => handleNavClick(e, 'publications')}
                className="text-sm font-medium hover:text-[#b7221c] py-2 transition-colors"
              >
                PUBLICACIONES
              </a>
            </nav>
          </div>
        )}
      </div>
    </header>
  )
}
