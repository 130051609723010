import React, { useState, useEffect } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'

export default function HeroCarousel() {
  const slides = [
    {
      image: "portada1.jpg",
      title: "",
      subtitle: "",
    },
    {
      image: "portada2.jpg",
      title: "",
      subtitle: "",
    },
    {
      image: "portada3.jpg",
      title: "",
      subtitle: "",
    },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length)
    }, 5000)
    return () => clearInterval(timer)
  }, [])

  return (
    <div className="carousel-container">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`${
            currentSlide === index ? 'relative opacity-100' : 'absolute inset-0 opacity-0'
          } transition-opacity duration-1000`}
        >
          <div className="w-full">
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              className="carousel-image"
            />
          </div>
          <div className="absolute inset-0 bg-stone-900/60" />
          <div className="absolute inset-0 flex items-center justify-center px-4">
            <div className="text-center text-white">
              <h2 className="text-3xl font-bold mb-4">
                {slide.title}
              </h2>
              <p className="text-lg">
                {slide.subtitle}
              </p>
            </div>
          </div>
        </div>
      ))}
      <button
        className="absolute left-4 top-1/2 -translate-y-1/2 rounded-full bg-stone-800/20 p-3 text-white backdrop-blur-sm transition-all hover:bg-stone-700/30 focus:outline-none focus:ring-2 focus:ring-white"
        onClick={() => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)}
      >
        <ChevronLeft className="h-6 w-6" />
      </button>
      <button
        className="absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-stone-800/20 p-3 text-white backdrop-blur-sm transition-all hover:bg-stone-700/30 focus:outline-none focus:ring-2 focus:ring-white"
        onClick={() => setCurrentSlide((prev) => (prev + 1) % slides.length)}
      >
        <ChevronRight className="h-6 w-6" />
      </button>
    </div>
  )
}
