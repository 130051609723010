import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4 max-w-4xl">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Política de Privacidad</h1>
        
        <div className="space-y-8 text-gray-600">
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Información General</h2>
            <p className="mb-4">
              En Guereca Abogados, nos comprometemos a proteger y respetar su privacidad. Esta política establece las bases sobre las cuales procesaremos cualquier dato personal que recopilemos de usted o que nos proporcione.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Información que Recopilamos</h2>
            <p className="mb-4">Podemos recopilar y procesar los siguientes datos:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Información que nos proporciona al completar formularios en nuestro sitio web</li>
              <li>Información proporcionada al contactarnos por cualquier medio</li>
              <li>Detalles de sus visitas a nuestro sitio web y los recursos a los que accede</li>
              <li>Información necesaria para la prestación de servicios legales</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Uso de la Información</h2>
            <p className="mb-4">Utilizamos la información que tenemos sobre usted de las siguientes maneras:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Para proporcionar nuestros servicios legales</li>
              <li>Para cumplir con nuestras obligaciones legales y profesionales</li>
              <li>Para mantenerle informado sobre nuestros servicios</li>
              <li>Para mejorar nuestros servicios y experiencia del usuario</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Seguridad de Datos</h2>
            <p className="mb-4">
              Nos comprometemos a garantizar que su información esté segura. Para evitar el acceso o divulgación no autorizados, hemos implementado procedimientos físicos, electrónicos y administrativos adecuados para salvaguardar y asegurar la información que recopilamos.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Sus Derechos</h2>
            <p className="mb-4">Usted tiene derecho a:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Solicitar acceso a sus datos personales</li>
              <li>Solicitar la corrección de datos inexactos</li>
              <li>Solicitar la eliminación de sus datos</li>
              <li>Oponerse al procesamiento de sus datos</li>
              <li>Solicitar la limitación del procesamiento de sus datos</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Contacto</h2>
            <p className="mb-4">
              Para ejercer cualquiera de sus derechos o si tiene preguntas sobre esta Política de Privacidad, puede contactarnos a través de:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Correo electrónico: contacto@guerecabogados.com</li>
              <li>Teléfono: 55 3055 5118</li>
              <li>Dirección: Canova 41, Colonia Insurgentes Mixcoac, Alcaldía Benito Juárez, CDMX</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Cambios en esta Política</h2>
            <p>
              Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Cualquier cambio que realicemos en nuestra política de privacidad se publicará en esta página y, cuando corresponda, se le notificará por correo electrónico.
            </p>
          </section>
        </div>

        <div className="mt-12 text-sm text-gray-500">
          <p>Última actualización: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  )
}
