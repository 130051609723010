import React from 'react'
import { Building2, Shield, Scale, Users } from 'lucide-react'

export default function BusinessAttention() {
  const services = [
    {
      icon: Building2,
      title: "Consultoría Legal Empresarial",
      description: "Asesoramiento integral en materia legal para empresas, incluyendo cumplimiento normativo y gestión de riesgos legales."
    },
    {
      icon: Shield,
      title: "Protección Corporativa",
      description: "Estrategias de prevención y defensa legal para proteger los intereses de su empresa y sus directivos."
    },
    {
      icon: Scale,
      title: "Auditoría Legal",
      description: "Revisión exhaustiva de procesos y documentación para garantizar el cumplimiento normativo y minimizar riesgos."
    },
    {
      icon: Users,
      title: "Capacitación Empresarial",
      description: "Programas de formación especializados para equipos directivos en materia de cumplimiento legal y mejores prácticas."
    }
  ]

  return (
    <section id="atencion" className="relative py-16 bg-gray-50 text-black">
      <div className="absolute inset-0">
        <div className="absolute inset-0  " />
      </div>
      
      <div className="container mx-auto px-4 relative">
        <div className="text-center">
          <h2 className="text-3xl sm:text-3xl md:text-3xl font-bold mb-6">
            ATENCIÓN EMPRESAS
          </h2>
          <p className="text-lg text-black max-w-3xl mx-auto">
            Ofrecemos soluciones legales integrales diseñadas específicamente para proteger y fortalecer su empresa
          </p>
        </div>
{/*}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => {
            const Icon = service.icon
            return (
              <div 
                key={index}
                className="bg-white/5 backdrop-blur-sm p-6 rounded-lg hover:bg-white/10 transition-all duration-300"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="p-3 bg-[#b7221c]/20 rounded-full mb-4">
                    <Icon className="w-8 h-8 text-[#b7221c]" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">
                    {service.title}
                  </h3>
                  <p className="text-gray-400">
                    {service.description}
                  </p>
                </div>
              </div>
            )
          })}
        </div>

*/}
      </div>
    </section>
  )
}
