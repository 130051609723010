import React from 'react'
import { BookOpen, Users, Award, Target } from 'lucide-react'

export default function TrainingSection() {
  const trainings = [
    {
      icon: BookOpen,
      title: "Capacitación Empresarial",
      description: "Programas de formación diseñados específicamente para empresas en materia de cumplimiento normativo, prevención de riesgos legales y mejores prácticas corporativas."
    },
    {
      icon: Users,
      title: "Diplomados, Maestrías y Doctorados",
      description: "Ofrecemos programas educativos de alto nivel para quienes deseen profundizar en el Derecho, diseñados para potenciar su desarrollo personal, académico y profesional."
    },
    {
      icon: Award,
      title: "Certificaciones",
      description: "Programas de certificación en áreas específicas del derecho, avalados por instituciones reconocidas y diseñados para elevar los estándares profesionales."
    },
    {
      icon: Target,
      title: "Programas a Medida",
      description: "Desarrollo de programas de capacitación personalizados según las necesidades específicas de cada organización o grupo profesional."
    }
  ]

  return (
    <section id="capacitacion" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-[#b7221c] mb-12">
          CAPACITACIÓN
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {trainings.map((item, index) => {
            const Icon = item.icon
            return (
              <div 
                key={index}
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 border-2 border-[rgb(185,28,28)]"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="p-3 bg-[rgb(185,28,28)]/10 rounded-full mb-4">
                    <Icon className="w-8 h-8 text-[rgb(185,28,28)]" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900">
                    {item.title}
                  </h3>
                  <p className="text-gray-600">
                    {item.description}
                  </p>
                </div>
              </div>
            )
          })}
        </div>

        <div className="mt-12 text-center">
          <p className="text-lg text-gray-700 max-w-3xl mx-auto">
            Nuestros programas de capacitación están diseñados para proporcionar conocimientos prácticos y actualizados, 
            permitiendo a los participantes desarrollar habilidades esenciales en el campo legal y corporativo.
          </p>
        </div>
      </div>
    </section>
  )
}
