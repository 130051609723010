import React, { useState } from 'react'

export default function Semblanza() {
  const [expandedSections, setExpandedSections] = useState({
    practica: false,
    publicaciones: false,
    docencia: false,
    grados: false,
    cursos: false,
    colegiacion: false
  });

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };

  return (
    <section className="relative w-full text-white">
      {/* Background with overlay */}
      <div className="absolute inset-0 z-0">
        <img
          src="./fondo3.jpg"
          alt="Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50" />
      </div>
      
      <div className="container mx-auto px-4 py-24 relative z-10">
        <div className="max-w-5xl mx-auto">
          {/* Header section with image */}
          <div className="flex flex-col md:flex-row items-center mb-16">
            <div className="md:w-1/3 mb-8 md:mb-0">
              <div className="w-64 h-64 mx-auto">
                <img
                  src="./fundador2.JPG"
                  alt="Miguel Antonio Gutiérrez Güereca"
                  className="w-full h-full object-cover rounded-full grayscale"
                />
              </div>
            </div>
            <div className="md:w-2/3 text-center md:text-left md:pl-12">
              <h1 className="text-4xl md:text-5xl font-bold mb-4" style={{ fontFamily: "'Playfair Display', serif" }}>
                MIGUEL ANTONIO GUTIÉRREZ GÜERECA
              </h1>
              <h2 className="text-xl md:text-2xl text-gray-300 mb-2">
                Socio Fundador
              </h2>
            </div>
          </div>

          {/* Semblanza content sections */}
          <div className="prose prose-lg prose-invert max-w-none">
            <h2 className="text-3xl font-bold mb-6 border-b border-primary-600 pb-3" style={{ fontFamily: "'Playfair Display', serif" }}>
              SEMBLANZA
            </h2>
            
            {/* Áreas de práctica - Collapsible Section */}
            <div className="border-b border-gray-700 py-4">
              <button 
                className="w-full flex justify-between items-center text-left focus:outline-none"
                onClick={() => toggleSection('practica')}
              >
                <h3 className="text-2xl font-bold">Áreas de práctica</h3>
                <span className="text-4xl">{expandedSections.practica ? '-' : '+'}</span>
              </button>
              
              {expandedSections.practica && (
                <div className="mt-4 pl-4">
                  <p className="mb-6">
                    Abogado postulante y titular de la firma Güereca Abogados, especializada en casos penales complejos, delitos fiscales, bancarios, financieros, fraudes e ilícitos económicos, enfocando también su práctica en el derecho penal en la defensa de personal del sector salud. Su experiencia le ha permitido defender a políticos de alto nivel acusados de enriquecimiento ilícito, cohecho y otros delitos cometidos por servidores públicos, incluidos médicos. Además, cuenta con experiencia en derecho penal empresarial y en la implementación de programas de prevención (compliance) para ayudar a las corporaciones a prevenir delitos relacionados con sus actividades comerciales.
                  </p>
                </div>
              )}
            </div>
            
            {/* Publicaciones - Collapsible Section */}
            <div className="border-b border-gray-700 py-4">
              <button 
                className="w-full flex justify-between items-center text-left focus:outline-none"
                onClick={() => toggleSection('publicaciones')}
              >
                <h3 className="text-2xl font-bold">Publicaciones</h3>
                <span className="text-4xl">{expandedSections.publicaciones ? '-' : '+'}</span>
              </button>
              
              {expandedSections.publicaciones && (
                <div className="mt-4 pl-4">
                  <ul className="list-disc pl-6 mb-8">
                    <li className="mb-2">Libro: Introducción al Proceso Penal Acusatorio.</li>
                    <li className="mb-2">Libro: El Procedimiento de Ejecución Penal en México.</li>
                    <li className="mb-2">Libro: El Interés Superior de la Niñez.</li>
                    <li className="mb-2">Libro: Diversos Artículos Sobre Derecho Penal y Procesal Penal.</li>
                  </ul>
                </div>
              )}
            </div>
            
            {/* Docencia - Collapsible Section */}
            <div className="border-b border-gray-700 py-4">
              <button 
                className="w-full flex justify-between items-center text-left focus:outline-none"
                onClick={() => toggleSection('docencia')}
              >
                <h3 className="text-2xl font-bold">Docencia</h3>
                <span className="text-4xl">{expandedSections.docencia ? '-' : '+'}</span>
              </button>
              
              {expandedSections.docencia && (
                <div className="mt-4 pl-4">
                  <ul className="list-disc pl-6 mb-8">
                    <li className="mb-2">Catedrático del Instituto Nacional de Ciencias Penales.</li>
                    <li className="mb-2">Catedrático del Colegio Comprender.</li>
                  </ul>
                </div>
              )}
            </div>
            
            {/* Grados académicos - Collapsible Section */}
            <div className="border-b border-gray-700 py-4">
              <button 
                className="w-full flex justify-between items-center text-left focus:outline-none"
                onClick={() => toggleSection('grados')}
              >
                <h3 className="text-2xl font-bold">Grados académicos</h3>
                <span className="text-4xl">{expandedSections.grados ? '-' : '+'}</span>
              </button>
              
              {expandedSections.grados && (
                <div className="mt-4 pl-4">
                  <ul className="list-disc pl-6 mb-8">
                    <li className="mb-2">Licenciatura en Derecho.</li>
                    <li className="mb-2">Especialidad en Juicios Orales.</li>
                    <li className="mb-2">Maestría en Ciencias Penales.</li>
                    <li className="mb-2">Doctorado en Derecho.</li>
                  </ul>
                </div>
              )}
            </div>
            
            {/* Cursos internacionales - Collapsible Section */}
            <div className="border-b border-gray-700 py-4">
              <button 
                className="w-full flex justify-between items-center text-left focus:outline-none"
                onClick={() => toggleSection('cursos')}
              >
                <h3 className="text-2xl font-bold">Cursos internacionales</h3>
                <span className="text-4xl">{expandedSections.cursos ? '-' : '+'}</span>
              </button>
              
              {expandedSections.cursos && (
                <div className="mt-4 pl-4">
                  <ul className="list-disc pl-6 mb-8">
                    <li className="mb-2">Teoría del Delito, Gottingen Alemania.</li>
                    <li className="mb-2">Especialidad en Derechos Humanos, Universidad de Zaragoza España.</li>
                    <li className="mb-2">Especialidad en Cumplimiento Normativo, Universidad de Castilla Mancha España.</li>
                  </ul>
                </div>
              )}
            </div>
            
            {/* Colegiación - Collapsible Section */}
            <div className="border-b border-gray-700 py-4">
              <button 
                className="w-full flex justify-between items-center text-left focus:outline-none"
                onClick={() => toggleSection('colegiacion')}
              >
                <h3 className="text-2xl font-bold">Colegiación</h3>
                <span className="text-4xl">{expandedSections.colegiacion ? '-' : '+'}</span>
              </button>
              
              {expandedSections.colegiacion && (
                <div className="mt-4 pl-4">
                  <ul className="list-disc pl-6 mb-8">
                    <li className="mb-2">Barra Mexicana de Abogados.</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
