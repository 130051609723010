import React from 'react'

const partners = [
  {
    name: 'MIGUEL ANTONIO GUTIÉRREZ GÜERECA',
    image: './fundador2.JPG',
    role: 'Socio Fundador',
    description: '',
  },
]

const associates = [
  {
    name: 'RICARDO GONZALEZ CHAVEZ',
    role: 'Asociado Senior',
  },
  {
    name: 'JOSÉ RUBEN SANCHEZ LÓPEZ',
    role: 'Asociado',
  },
  {
    name: 'CUAUHTEMOC TORRES BELTRAN',
    role: 'Asociado',
  },
  {
    name: 'GERARDO CHILPA HERNANDEZ',
    role: 'Asociado',
  },
  {
    name: 'JOSÉ ISRAEL LANDEROS SILVA',
    role: 'Asociado',
  },
  {
    name: 'ANDREA ECHEVERRIA GUARNEROS',
    role: 'Asociado',
  },
  
]

export default function Associates() {
  return (
    <section id="associates" className="relative w-full py-20 text-white">
      <div className="absolute inset-0 z-0">
        <img
          src="fondo3.jpg"
          alt="Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0" />
      </div>
      <div className="container mx-auto px-4 relative z-10">
        {/* Partners Section */}
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mb-16">SOCIO FUNDADOR</h2>
        <div className="grid md:grid-cols-1 gap-16 mb-24">
          {partners.map((partner) => (
            <div key={partner.name} className="flex flex-col items-center text-center">
              <div className="w-64 h-64 mb-6">
                <img
                  src={partner.image}
                  alt={partner.name}
                  className="w-full h-full object-cover rounded-full grayscale"
                />
              </div>
              <h3 className="text-4xl md:text-5xl font-bold mb-4" style={{ fontFamily: "'Playfair Display', serif" }}>{partner.name}</h3>
              <p className="text-lg text-gray-300 max-w-2xl">{partner.description}</p>
              <a href="/semblanza" className="text-primary-600 hover:text-primary-500 transition-colors">
                VER SEMBLANZA
              </a>
            </div>
          ))}
        </div>

        {/* Associates Section */}
        <h2 className="text-3xl sm:text-3xl md:text-3xl font-bold text-center mb-16">ASOCIADOS</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-x-2 gap-y-4 md:gap-x-4 md:gap-y-6 max-w-5xl mx-auto">
          {associates.map((associate, index) => (
            <div key={associate.name} className="flex flex-col items-center text-center px-1">
              <h3 className="text-sm md:text-base font-normal">{associate.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
