import React, { useState } from 'react'

export default function Footer() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const mailtoLink = `mailto:contact@requena.com?subject=Contact Form&body=Name: ${formData.name}%0D%0AEmail: ${formData.email}%0D%0AMessage: ${formData.message}`
    window.location.href = mailtoLink
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <footer className="w-full bg-black text-stone-300 py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Column 1: Logo and Map */}
          <div className="space-y-6">
            <img src="/logoblanco.png" alt="Guereca Abogados" className="h-16"  />
            <div className="w-full h-[250px] overflow-hidden rounded-lg">
            
            <p className="text-sm mb-2">
                Firma especializada en derecho con experiencia profesional reconocida en México.
            </p>
            
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.951210405344!2d-99.186584424059!3d19.3712651426354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff8f51f05181%3A0x8de7950d0a88869!2sCanova%2041%2C%20Insurgentes%20Mixcoac%2C%20Benito%20Ju%C3%A1rez%2C%2003920%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1738005559011!5m2!1ses-419!2smx" 
                width="100%" 
                height="100%" 
                style={{ border: 0 }} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title="Ubicación de la oficina"
              />
            </div>
          </div>

          {/* Column 2: Contact Info */}
          <div className="space-y-6">
            <h3 className="text-white font-semibold uppercase">CONTACTO</h3>
            <div className="space-y-2 text-sm">
              <p>Canova 41</p>
              <p>Colonia Insurgentes Mixcoac</p>
              <p>Alcaldía Benito Juárez</p>
              <p>03920, CDMX</p>
            </div>
            <div className="pt-4">
              <a href="/privacidad" className="text-sm hover:text-white transition-colors duration-200">
                Política de Privacidad
              </a>
            </div>
          </div>

          {/* Column 3: Contact Form */}
          <div className="md:col-span-1">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="text-sm">Nombre (*)</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full mt-1 px-3 py-2 bg-white text-stone-900 rounded"
                />
              </div>
              <div>
                <label htmlFor="email" className="text-sm">Email (*)</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full mt-1 px-3 py-2 bg-white text-stone-900 rounded"
                />
              </div>
              <div>
                <label htmlFor="message" className="text-sm">Mensaje</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="w-full mt-1 px-3 py-2 bg-white text-stone-900 rounded"
                />
              </div>
              <button
                type="submit"
                className="bg-primary-600 text-white px-6 py-2 rounded hover:bg-primary-700 transition-colors duration-200"
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t border-stone-800 pt-8 text-center text-sm text-stone-400">
          <p>&copy; {new Date().getFullYear()} Guerecea Abogados. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  )
}
