import React from 'react'

const publications = [
  {
    title: 'La importancia del compliance penal',
    excerpt: 'Análisis sobre la implementación de programas de cumplimiento normativo.',
    image: 'https://placehold.co/800x600?text=Publication+1',
  },
  {
    title: 'Nuevas tendencias en derecho penal',
    excerpt: 'Estudio sobre las últimas reformas y su impacto en la práctica legal.',
    image: 'https://placehold.co/800x600?text=Publication+2',
  },
  {
    title: 'Gestión de crisis empresarial',
    excerpt: 'Estrategias efectivas para el manejo de situaciones críticas.',
    image: 'https://placehold.co/800x600?text=Publication+3',
  },
]

export default function Publications() {
  return (
    <section id="publications" className="w-full py-12 md:py-16 lg:py-20 bg-stone-50">
      
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-stone-900">Publicaciones</h2>
        <div className="mt-10 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {publications.map((pub) => (
            <div key={pub.title} className="group overflow-hidden rounded-xl bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="aspect-video overflow-hidden">
                <img
                  src={pub.image}
                  alt={pub.title}
                  className="h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-stone-900 group-hover:text-primary-600 transition-colors duration-200">
                  {pub.title}
                </h3>
                <p className="mt-2 text-stone-600">{pub.excerpt}</p>
                <button className="mt-4 text-primary-600 font-medium hover:text-primary-700 focus:outline-none inline-flex items-center gap-1 transition-colors duration-200">
                  Leer más
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
