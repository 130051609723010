import React from 'react'
import { X } from 'lucide-react'

export default function EthicsModal({ isOpen, onClose }) {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-2xl font-bold text-[rgb(185,28,28)]">
              Ética Jurídica dentro de Guereca Abogados
            </h3>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <X size={24} />
            </button>
          </div>
          
          <div className="prose max-w-none">
            <p className="text-gray-700 mb-6">
              Conforme a la Oficina del Alto Comisionado de las Naciones Unidas para los 
              Derechos Humanos (ACNUDH) existen "Principios Básicos sobre la Función de 
              los Abogados" los cuales son los ejes rectores de nuestra firma.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
