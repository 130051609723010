import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import TopNav from '../components/top-nav'
import MainNav from '../components/main-nav'
import HeroCarousel from '../components/hero-carousel'
import AboutSection from '../components/about-section'
import PracticeAreas from '../components/practice-areas'
import Associates from '../components/associates'
import Publications from '../components/publications'
import Gallery from '../components/gallery'
import LogoSection from '../components/logo-section'
import ContactForm from '../components/contact-form'
import PreFooter from '../components/pre-footer'
import Footer from '../components/footer'
import PrivacyPolicy from '../components/privacy-policy'
import TrainingSection from '../components/training-section'
import BusinessAttention from '../components/business-attention'
import Semblanza from '../components/semblanza'

function HomePage() {
  const location = useLocation();
  
  useEffect(() => {
    // Manejar el desplazamiento a secciones después de la navegación
    if (location.state && location.state.scrollToId) {
      setTimeout(() => {
        const element = document.getElementById(location.state.scrollToId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Pequeño retraso para asegurar que los componentes se han renderizado
    }
  }, [location]);
  
  return (
    <>
      <HeroCarousel />
      <BusinessAttention />    
      <PracticeAreas />
      <TrainingSection />
      <Associates />
      <Publications />

    </>
  )
}

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-background font-sans antialiased">
        <TopNav />
        <MainNav />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacidad" element={<PrivacyPolicy />} />
            <Route path="/semblanza" element={<Semblanza />} />
          </Routes>
        </main>
        <Footer />
        <LogoSection />
      </div>
    </Router>
  )
}

export default App
